import { Input, Card, Form } from "antd"
import Button from "react-bootstrap/Button";

import { APP_NAME, APP_DOMAIN } from '../../../../data/appData';
import { BookOutlined, PhoneOutlined } from "@ant-design/icons";
import { EmailOutlined } from "@material-ui/icons";
import { styled } from "@material-ui/core";
import AuthLayout from "../../../layouts/AuthLayout"
import { toAbsoluteUrl } from "../../../../_metronic/_helpers"

const HelpIcon = styled('span')(() => ({
	display: 'inline-block',
	width: '16px',
	color: '#B5B5C3',
	marginRight: '.5rem',
	'& svg': {
		width: '16px',
	}
}))

const onSubmit = (value) => {
	window.location.href = `https://${value?.url}${APP_DOMAIN}`
}

function LoginDomain() {
	return (
		<AuthLayout
			ktLoginStyle={{ backgroundImage: `url(${toAbsoluteUrl("/media/bg/bg-login.jpg")})` }}
			asideContent={
				<>
					<p className="font-weight-bold font-size-h3">
						One system, limitless use cases.
					</p>
					<p className="font-size-lg">
						For finance and beyond!
					</p>
				</>
			}
		>
			<div className="d-flex flex-column flex-root">
				<div
					className="login login-4 login-signin-on d-flex flex-row-fluid"
					id="kt_login"
				>
					<div className="d-flex flex-center flex-column flex-row-fluid bgi-size-cover bgi-position-top bgi-no-repeat">
						<Card className="shadow-4">
							<div className="login-form login-signin" id="kt_login_signin_form">
								<div className="mb-10">
									<h3>Welcome to {APP_NAME}</h3>
									<p>Please enter your {APP_NAME} login domain</p>
								</div>
								<Form onFinish={onSubmit}>
									<fieldset>
										<Form.Item
											rules={[{ required: true, message: 'Please input your domain' }]}
											name="url">
											<Input
												addonBefore="https://"
												addonAfter={APP_DOMAIN}
												placeholder="yourdomain" />
										</Form.Item>
										<Form.Item>
											<Button
												id="kt_login_signin_submit"
												type="submit"
												className="btn btn-primary font-weight-bold px-9"
												block
											>
												<span>Continue</span>
											</Button>
										</Form.Item>
										<p className="mt-4">If you need help logging in, please have your username ready and contact UniFi on:</p>
										<div className="vcard">
											<div>
												<HelpIcon>
													<EmailOutlined />
												</HelpIcon>
												<a href="mailto:support@unifiplatform.com" className="email">support@unifiplatform.com</a>
											</div>
											<div className="mt-4">
												<HelpIcon>
													<PhoneOutlined />
												</HelpIcon>
												<a href="tel:+442074569839" className="tel">+44 20 7456 9839</a>
											</div>
											<div className="mt-4">
												<HelpIcon>
													<BookOutlined />
												</HelpIcon>
												<a href="https://docs.unifiplatform.com/" className="tel">Need help ?</a>
											</div>
										</div>
									</fieldset>
								</Form>
							</div>
						</Card>
					</div>
				</div>
			</div>
		</AuthLayout>
	);
}
export default LoginDomain
