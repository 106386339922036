const webhooksEndpoints = {
    getWebhookDetail(id) {
        return {
            url: `/api/webhook/${id}/detail`,
            method: 'GET'
        }
    },
    getWebhookLog(params) {
        return {
            url: `/api/webhook/executionlog`,
            method: 'GET',
            params
        }
    },
    saveWebhook(data) {
        return {
            url: '/api/webhook/save',
            method: 'POST',
            data
        }
    },
    getWebhookDataTables(data) {
        return {
            url: '/api/webhook/datatables',
            method: 'POST',
            data: data.data
        }
    },
    bulkDeleteWebhook(data) {
        return {
            url: '/api/webhook/deletebyids',
            method: 'POST',
            data
        }
    },
    deleteWebhook(id) {
        return {
            url: '/api/webhook/deletebyid',
            method: 'POST',
            params: {
                id,
            },
        }
    },
}

export default webhooksEndpoints