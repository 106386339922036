import { omit, mapGroupOptionsTree } from "@utils/Q";
import { groupBy } from "@utils/collection-q";
import normalizePayload from "@utils/form-editor/normalize-payload";
import {
  flowToElements,
  elsaWorkflowToReactFlow,
  mapRecordDisplayStatus,
} from "@utils/form-editor/normalize-response";
import { initRequest, setLoading } from "@utils/actionMethods";
import { setDefaultPermission } from "@utils/form-details/permission";
import message from "@utils/message";
import api from "@api";
import { INIT_FIELD_TYPES } from "@components/form-editor/const";
import { awsFile } from "../../app/components/form-editor/utils";
import { isEqual, sortBy } from "lodash";

const formEditorActions = {
  setLastHistory({ moduleId, tab, page, size }) {
    return (dispatch) => {
      dispatch({
        type: "SET_LAST_HISTORY",
        payload: {
          moduleId,
          tab,
          page,
          size,
        },
      });
    };
  },
  createProcessFromScratch(payload) {
    return async (dispatch) => {
      const request = initRequest(dispatch);
      let result = {};
      await request("createProcessFromScratch", payload, async (response) => {
        result = { data: response };
      });
      return result;
    };
  },
  getFormList(params = {}) {
    return async (dispatch) => {
      const request = initRequest(dispatch);
      let formList = []; // {}
      await request("getFormList", params, async (data) => {
        // (data?.data || []).map(item => ({
        //     id: item.id,
        //     title: item.display_name, // item['display_name']
        //     status: item.status
        // }))
        formList = (data?.data || []).map((item) => ({
          // ...omit(item, ['display_name']),
          ...item,
          title: item.display_name,
        }));
        dispatch({
          type: "SET_FORM_LIST",
          payload: formList,
        });
      });
      return formList;
    };
  },
  setFields(payload) {
    return (dispatch) => {
      dispatch({
        type: "SET_FIELDS",
        payload,
      });
    };
  },
  getFormDetail(id, token) {
    return async (dispatch, getState) => {
      if (!id) return;
      const request = initRequest(dispatch);
      const { formEditorReducer } = getState();
      const { formList } = formEditorReducer;
      let sections = [];
      let sectionsPublished = [];
      let fields = [];
      let tableFields = [];
      let publishedFields = [];
      let publishedTableFields = [];
      let flowData = {};
      let defaultConfig = null;
      let permission = [];
      let rawPermission = {};
      let workflowData = {};
      let modulePublished = {};
      let info = {};
      let isReassignmentEnabled = true;
      let emailTemplates = {};
      // GenAi chat context (thread) id
      let threadId = null;
      const fieldsWalker = (arr) => {
        return arr.reduce((allFields, section) => {
          const rowFields = section.rows.reduce((allRowFields, row) => {
            return [...allRowFields, ...row.fields];
          }, []);
          return [...allFields, ...rowFields];
        }, []);
      };
      await dispatch(setLoading("getFormDetail", true));
      await request("getModuleConfig", id, ({ data }) => {
        isReassignmentEnabled = data?.is_reassignment_disabled ? false : true;
      });
      await request(
        "getFormDetail",
        { id },
        async (responseData) => {
          const { data } = responseData;
          const moduleDraft =
            data && data["module_draft"]
              ? JSON.parse(data["module_draft"])
              : null;
          const workflowDraft =
            data && data["workflow_draft"]
              ? JSON.parse(data["workflow_draft"])
              : null;
          modulePublished = data ? JSON.parse(data["module_published"]) : null;
          const populateSections = (schema) => {
            return (schema?.fieldGroups || []).length
              ? (schema.fieldGroups || []).map((s) => ({
                  ...s,
                  rows: Object.entries(groupBy(s.fields, "rowIndex")).map(
                    ([rowIndex, rowFields]) => {
                      const mapEditorTypes = {
                        remoteurl: "remote-lookup",
                      };
                      return {
                        rowIndex,
                        fields: rowFields.map((rowField) => {
                          const {
                            editorType,
                            name,
                            displayName,
                            label,
                            ...f
                          } = rowField;
                          const currentConfig = INIT_FIELD_TYPES.find(
                            ({ fieldType }) => {
                              return (
                                fieldType ===
                                (mapEditorTypes[editorType] || editorType)
                              );
                            }
                          );
                          return {
                            ...f,
                            id: name,
                            fieldType: mapEditorTypes[editorType] || editorType,
                            fieldName: displayName,
                            displayName,
                            label: currentConfig ? currentConfig.label : label,
                          };
                        }),
                      };
                    }
                  ),
                }))
              : [];
          };
          if (moduleDraft) {
            const isConfigDraft = isEqual(
              moduleDraft?.defaultConfig,
              modulePublished?.defaultConfig
            );
            defaultConfig = isConfigDraft
              ? modulePublished?.defaultConfig
              : moduleDraft?.defaultConfig;
            sections = populateSections(moduleDraft);
            sectionsPublished = populateSections(modulePublished);
            fields = fieldsWalker(
              sections.filter(({ isTable }) => !isTable)
            ).filter((field) => field.fieldType !== "empty-col");
            tableFields = fieldsWalker(
              sections.filter(({ isTable }) => isTable)
            );
            publishedFields = fieldsWalker(
              sectionsPublished.filter(({ isTable }) => !isTable)
            ).filter((field) => field.fieldType !== "empty-col");
            publishedTableFields = fieldsWalker(
              sectionsPublished.filter(({ isTable }) => isTable)
            );

            let isPublished = Boolean(data.module_published); // data['module_published']
            const permissionDraft =
              typeof moduleDraft?.permission === "string"
                ? setDefaultPermission({
                    permission: JSON.parse(moduleDraft.permission),
                    sections: sections,
                  })
                : null;
            const permissionPublished =
              typeof modulePublished?.permission === "string"
                ? setDefaultPermission({
                    permission: JSON.parse(modulePublished?.permission),
                    sections: sectionsPublished,
                  })
                : null;
            permission = permissionDraft?.rules || [];
            rawPermission = permissionDraft?.rawPermission || {};
            const file =
              data.help_file_url && data.help_file_url?.length > 0
                ? await awsFile(data.help_file_url, token)
                : null;
            flowData = {
              ...omit(moduleDraft, ["fieldGroups"]),
              id,
              isPublished,
              created_by: data.created_by,
              created_on: data.created_on,
              modified_by: data.modified_by,
              modified_on: data.modified_on,
              module_version: data.module_version,
              status: isPublished ? "Published" : "Draft", // data.status,
              formCategories: data.application_module_category_id || "",
              formDescription: data.module_description || "",
              formName: data.display_name || "",
              description: data.module_description || "",
              displayName: data.display_name || "",
              permission: permissionPublished?.rules || [],
              helpFileUrl: data.help_file_url,
              awsHelpUrl: file,
              ocrProviderId: data.ocr_provider_id,
              ocrProviderName: data.ocr_provider_name,
              machineLearningServiceId: data.ml_service_id,
              appIcon: data.app_icon,
              addToQuickLaunch: data.add_to_quick_launch
            };
          }
          if (
            workflowDraft &&
            (workflowDraft?.connections || []).length > 0 &&
            (workflowDraft.connections[0]?.destinationActivityId ||
              workflowDraft.connections[0]?.targetActivityId)
          ) {
            workflowData = elsaWorkflowToReactFlow(workflowDraft);
          }
          if (data) {
            emailTemplates = {
              id,
              submit_subject_email_template: data.submit_subject_email_template,
              reassign_subject_email_template:
                data.reassign_subject_email_template,
              rejected_subject_email_template:
                data.rejected_subject_email_template,
              restart_subject_email_template:
                data.restart_subject_email_template,
              sendback_subject_email_template:
                data.sendback_subject_email_template,
              completed_subject_email_template:
                data.completed_subject_email_template,
              approve_subject_email_template:
                data.approve_subject_email_template,
              submit_body_email_template: data.submit_body_email_template,
              reassign_body_email_template: data.reassign_body_email_template,
              rejected_body_email_template: data.rejected_body_email_template,
              restart_body_email_template: data.restart_body_email_template,
              sendback_body_email_template: data.sendback_body_email_template,
              completed_body_email_template: data.completed_body_email_template,
              approve_body_email_template: data.approve_body_email_template,
            };
            threadId = data.thread_id
          }
        },
        async (response) => {
          await dispatch(setLoading("getFormDetail", false));
          await dispatch({
            type: "SET_FIELDS",
            payload: fields,
          });
          await dispatch({
            type: "SET_FORM_FIELDS",
            payload: {
              formId: id,
              formFields: fields,
            },
          });
          await dispatch({
            type: "SET_TABLE_FIELDS",
            payload: {
              formId: id,
              tableFields: tableFields,
            },
          });
          await dispatch({
            type: "SET_FORM_DETAILS",
            payload: {
              formId: id,
              formDetails: {
                sections,
                sectionsPublished,
                flowData,
                defaultConfig,
                permission,
                rawPermission,
                workflowData,
                modulePublished,
                info,
                fields,
                tableFields,
                publishedFields,
                publishedTableFields,
                isReassignmentEnabled,
                threadId,
              },
            },
          });
          await dispatch({
            type: "SET_WORKFLOW",
            payload: {
              elements: flowToElements(workflowData),
            },
          });

          if (
            response?.data?.id &&
            formList.length &&
            !formList.find((item) => item.id === response.data.id)
          ) {
            dispatch({
              type: "SET_FORM_LIST",
              payload: [
                ...formList,
                {
                  ...flowData,
                  title: response.data.display_name,
                },
              ],
            });
          }
        }
      );
      return {
        sections,
        sectionsPublished,
        flowData,
        defaultConfig,
        permission,
        rawPermission,
        modulePublished,
        info,
        fields,
        tableFields,
        publishedFields,
        publishedTableFields,
        isReassignmentEnabled,
        emailTemplates,
        threadId,
      };
    };
  },
  getPermissibleList() {
    return async (dispatch) => {
      const request = initRequest(dispatch);
      await request("getPermissibleList", {}, async (res) => {
        // { data }
        if (res && res.data) {
          const payload = (res.data || []).map((option) => ({
            ...option,
            value: option.id,
            label: option.key_name, // option["key_name"]
          }));
          await dispatch({
            type: "SET_PERMISSIBLE_LIST",
            payload,
          });
        }
      });
    };
  },
  addPermissibleList(list) {
    return async (dispatch, getState) => {
      const request = initRequest(dispatch);
      const { formEditorReducer } = getState();
      const { permissibleList } = formEditorReducer;
      const body = normalizePayload.listKeyValuePayload(list);
      const isEdit = body?.id;
      let result = {};
      await dispatch(setLoading("getPermissibleListItems", true));
      await request("addPermissibleList", body, async (data = []) => {
        if (data?.data && data?.success) {
          const optionValue = {
            value: data?.data["record_id"],
            label: body["key_name"],
          };
          result = optionValue;

          if (isEdit) {
            const editedListIndex = permissibleList.findIndex(
              ({ value }) => value === body?.id
            );
            const list = [...permissibleList];
            list.splice(editedListIndex, 1, optionValue);
            await dispatch({
              type: "SET_PERMISSIBLE_LIST",
              payload: list,
            });
          } else {
            await dispatch({
              type: "ADD_PERMISSIBLE_LIST",
              payload: {
                ...list,
                ...optionValue,
              },
            });
          }
        }
      });
      return result;
    };
  },
  getPermissibleListCategoryOptions() {
    return async (dispatch) => {
      const request = initRequest(dispatch);
      await request("getPermissibleListCategoryOptions", {}, async (res) => {
        // { data }
        if (res && res.data) {
          const payload = (res.data || []).map((option) => ({
            value: option.id,
            label: option.category_name, // option["category_name"]
          }));
          await dispatch({
            type: "SET_PERMISSIBLE_LIST_CATEGORY_OPTIONS",
            payload,
          });
        }
      });
    };
  },
  addPermissibleListCategoryOption({ category: categoryName }) {
    return async (dispatch) => {
      try {
        const { data } = await api.addPermissibleListCategoryOption(
          categoryName
        );
        const { record_id: categoryId } = data?.data || {};

        return dispatch({
          type: "ADD_PERMISSIBLE_LIST_CATEGORY_OPTIONS",
          payload: {
            value: categoryId,
            label: categoryName,
          },
        });
      } catch (e) {}
    };
  },
  getPermissibleListItems(listId) {
    return async (dispatch) => {
      try {
        await dispatch(setLoading("getPermissibleListItems", true));
        const { data } = await api.getPermissibleListItems(listId);
        const items = data ? data.data?.values : [];
        await dispatch({
          type: "SET_PERMISSIBLE_LIST_ITEMS",
          payload: {
            listId,
            items,
          },
        });
        await dispatch(setLoading("getPermissibleListItems", false));
      } catch (e) {
      } finally {
        dispatch(setLoading("getPermissibleListItems", false));
      }
    };
  },
  setPermissibleListItems(listId, items) {
    return async (dispatch) => {
      try {
        await dispatch({
          type: "SET_PERMISSIBLE_LIST_ITEMS",
          payload: {
            listId,
            items,
          },
        });
      } catch (e) {}
    };
  },
  setPermissibleListCategories(listId, categories) {
    return async (dispatch) => {
      try {
        await dispatch({
          type: "SET_PERMISSIBLE_LIST_CATEGORIES",
          payload: {
            listId,
            categories,
          },
        });
      } catch (e) {}
    };
  },
  setFormPreview(payload) {
    return (dispatch) => {
      dispatch({
        type: "SET_PREVIEW_FORM",
        payload,
      });
    };
  },
  saveForm(payload, withDispatch = false) {
    return async (dispatch, getState) => {
      const request = initRequest(dispatch);
      const { workflowReducer } = getState();
      const { workflow } = workflowReducer;
      const { validUserFields } = payload;
      const moduleId = payload.flowData.id;
      const params = {
        id: moduleId,
        data: {
          ...normalizePayload.formPayload(payload),
          ...(payload?.permission?.length > 0 && {
            permission: JSON.stringify({
              rules: payload.permission,
              rawPermission: payload.rawPermission,
            }),
          }),
          ...(workflow?.elements?.length > 0 && {
            workflow: normalizePayload.workflowPayload({
              ...workflow,
              validUserFields,
            }),
          }),
          defaultConfig: payload.defaultConfig,
        },
      }

      // Use request (by setting withDispatch to true) 
      // to consume the loading state of the request from the store.
      return withDispatch
        ? await request('saveForm', params)
        : api.saveForm(params);
    };
  },
  applyConfigAllUser(moduleId) {
    return async () => {
      return api.applyConfigAllUser({
        moduleId
      });
    };
  },
  setUserConfig(payload) {
    const { moduleId, tabConfig, data } = payload
    return async () => {
      return api.setUserConfig({
        moduleId,
        tabConfig,
        data
      });
    };
  },
  deleteForm({ moduleId, onSuccess }) {
    return async (dispatch, getState) => {
      const { formEditorReducer } = getState();
      const { formList } = formEditorReducer;
      const temp = [...formList];
      const request = initRequest(dispatch);
      await dispatch({
        type: "DELETE_FORM_LIST_ITEM",
        payload: {
          formId: moduleId,
        },
      });
      return request("deleteForm", moduleId, (result) => {
        const { success } = result;
        if (success) {
          onSuccess && onSuccess(result);
        } else {
          dispatch({
            type: "SET_FORM_LIST",
            payload: temp,
          });
        }
      });
    };
  },
  setModuleReassignment(payload) {
    return async () => {
      return api.setModuleReassignment(payload);
    };
  },
  publish(payload) {
    return async (dispatch, getState) => {
      let result = {};
      const request = initRequest(dispatch);
      const { formEditorReducer } = getState();
      const { formList } = formEditorReducer;
      await request(
        "publish",
        { id: payload.flowData.id },
        async (response) => {
          result = { data: response };
          const list = [...formList];
          const existingModule = (module) => module.id === payload.flowData.id;
          const moduleIndex = list.findIndex(existingModule);
          if (moduleIndex !== -1) {
            const newModule = {
              ...list[moduleIndex],
              ...payload.flowData,
              defaultConfig: payload.defaultConfig,
              title: payload.flowData.displayName,
              isPublished: true,
              status: "Published",
            };
            list.splice(moduleIndex, 1, newModule);
            await dispatch({
              type: "SET_FORM_LIST",
              payload: list,
            });
          }
        }
      );
      return result;
    };
  },
  getModuleItems({ moduleId, query, page, orders, columns = [] }) {
    return (dispatch) => {
      const request = initRequest(dispatch);
      return request(
        "getModuleItems",
        {
          moduleId,
          data: normalizePayload.dataTablePayload({
            fields: columns,
            query,
            page,
            orders,
          }),
        },
        (response) => {
          const { data = [] } = response;
          dispatch({
            type: "SET_MODULE_ITEMS",
            payload: {
              moduleItems: {
                ...response,
                data: data.map(mapRecordDisplayStatus),
                page,
              },
              formId: moduleId,
            },
          });
        }
      );
    };
  },
  getListApplications(){
    return async (dispatch) => {
      const request = initRequest(dispatch);
      await request("getListApplications", 
        {},
        async (res) => {
          const { success = false, data = [] } = res || {};
          if (success && data) {
            await dispatch({
              type: "SET_APPLICATIONS_MODULE",
              payload: data
            });
          }
        }
      );
    };
},
  getModuleTasks({
    moduleId,
    query,
    page,
    orders,
    columns = [],
    filters = {},
  }) {
    return (dispatch) => {
      const request = initRequest(dispatch);
      return request(
        "getModuleTasks",
        {
          moduleId,
          data: normalizePayload.dataTablePayload({
            fields: columns,
            query,
            page,
            orders,
            filters,
          }),
        },
        async (response) => {
          if (
            (query || filters?.rules?.length) &&
            response.recordsFiltered !== undefined
          ) {
            response.recordsTotal = response.recordsFiltered;
          }
          await dispatch({
            type: "SET_MODULE_TASKS",
            payload: {
              moduleTasks: {
                ...response,
                page,
              },
              formId: moduleId || "all",
            },
          });
        }
      );
    };
  },
  uploadEntry(payload) {
    return async (dispatch) => {
      const request = initRequest(dispatch);
      return request("uploadEntry", payload);
    };
  },
  saveEntry(payload) {
    return async (dispatch) => {
      const request = initRequest(dispatch);
      return request("saveEntry", payload);
    };
  },
  saveRecordTemplate(payload) {
    return async (dispatch) => {
      const request = initRequest(dispatch);
      return request("saveRecordTemplate", payload);
    };
  },
  getSavedRecordTemplate(id) {
    return async (dispatch) => {
      const request = initRequest(dispatch);
      return request("getSavedRecordTemplate", id);
    };
  },
  renameRecordTemplate(payload) {
    return async (dispatch) => {
      const request = initRequest(dispatch);
      return request("renameRecordTemplate", payload);
    };
  },
  deleteRecordTemplate(payload) {
    return async (dispatch) => {
      const request = initRequest(dispatch);
      return request("deleteRecordTemplate", payload);
    };
  },
  setOrderIndexRecordTemplate(payload) {
    return async (dispatch) => {
      const request = initRequest(dispatch);
      return request("setOrderIndexRecordTemplate", payload);
    };
  },
  submitEntry(payload) {
    return async (dispatch) => {
      const request = initRequest(dispatch);
      return request("submitEntry", payload);
    };
  },
  setModuleItems({ moduleId, items }) {
    return (dispatch, getState) => {
      const { formEditorReducer } = getState();
      const { moduleItems } = formEditorReducer;
      dispatch({
        type: "SET_MODULE_ITEMS",
        payload: {
          moduleItems: {
            ...moduleItems[moduleId],
            data: items,
          },
          formId: moduleId,
        },
      });
    };
  },
  restartProcess(payload) {
    return async (dispatch) => {
      const request = initRequest(dispatch);
      return request("restartProcess", payload, null, (data) => {
        const {
          success,
          message: responseMessage = "Error restarting process",
        } = data || {};
        if (!success) {
          message.error(responseMessage);
        } else {
          message.success(responseMessage);
        }
      });
    };
  },
  deleteEntry(payload) {
    const { moduleId, recordId } = payload;
    return async (dispatch, getState) => {
      const { formEditorReducer } = getState();
      const { moduleItems } = formEditorReducer;
      const records = [...((moduleItems[moduleId] || {}).data || [])];
      const request = initRequest(dispatch);
      const items = [...records];
      items.splice(
        items.findIndex((item) => item.id === recordId),
        1
      );

      await dispatch(this.setModuleItems({ moduleId, items }));
      return request("deleteEntry", payload, null, (data) => {
        const { success, message: responseMessage = "Error deleting entry" } =
          data || {};
        if (!success) {
          message.error(responseMessage);
          dispatch(this.setModuleItems({ moduleId, items: records }));
        } else {
          message.success(responseMessage);
        }
      });
    };
  },
  deleteEntries(payload) {
    const { moduleId, recordIds } = payload;
    return async (dispatch, getState) => {
      const { formEditorReducer } = getState();
      const { moduleItems } = formEditorReducer;
      const request = initRequest(dispatch);
      const records = [...((moduleItems[moduleId] || {}).data || [])];
      const items = records.filter(({ id }) => !recordIds.includes(id));

      await dispatch(this.setModuleItems({ moduleId, items }));
      return request("deleteEntries", payload, null, (data) => {
        const { success, message: responseMessage = "Error deleting entry" } =
          data || {};
        if (!success) {
          message.error(responseMessage);
          dispatch(this.setModuleItems({ moduleId, items: records }));
        } else {
          message.success(responseMessage);
        }
      });
    };
  },
  lookup(payload) {
    const data = normalizePayload.lookupPayload(payload);

    return (dispatch) => {
      const request = initRequest(dispatch);
      return request("lookup", { data });
    };
  },
  aggregateLookup(payload) {
    const data = normalizePayload.aggregateLookupPayload(payload);

    return (dispatch) => {
      const request = initRequest(dispatch);
      return request("aggregateLookup", { data });
    };
  },
  getModuleLookup(id) {
    return async (dispatch) => {
      const request = initRequest(dispatch);
      await request("moduleLookup", id, ({ data = [] }) => {
        const options = data.map((item) => ({
          value: item.module_id,
          label: item.display_name,
          istable: item.is_table,
        }));
        dispatch({
          type: "SET_MODULE_LOOKUP",
          payload: options,
        });
      });
    };
  },
  getColumnLookup(id) {
    return async (dispatch) => {
      const request = initRequest(dispatch);
      await request("columnLookup", id, ({ data = [] }) => {
        const options = data.map((item) => ({
          value: item,
          label: item,
        }));
        dispatch({
          type: "SET_COLUMN_LOOKUP",
          payload: options,
        });
      });
    };
  },
  getModuleCategories() {
    return async (dispatch) => {
      const request = initRequest(dispatch);
      let returnData = {};
      
      await request("getModuleCategories", {}, async (data) => {
        const payload = mapGroupOptionsTree(data);
        await dispatch({
          type: "SET_MODULE_CATEGORIES",
          payload,
        });
        returnData = data;
      });
      return returnData;
    };
  },
  getMLProviders() {
    return async (dispatch) => {
      const request = initRequest(dispatch);
      let returnData = {};
      await request("getMLProviders", {}, async (data) => {
        const payload = (data?.items || []).map((option) => ({
          value: option.id,
          label: option["ocr_provider_name"],
        }));
        await dispatch({
          type: "SET_ML_PROVIDERS",
          payload,
        });
        returnData = data;
      });
      return returnData;
    };
  },
  getMLServices({providerId}) {
    return async (dispatch) => {
      const request = initRequest(dispatch);
      let returnData = {};
      await request("getMLServices", {providerId}, async (data) => {
        const payload = (data?.items || []).map((option) => ({
          value: option.id,
          label: option["ml_service_name"],
        }));
        await dispatch({
          type: "SET_ML_SERVICES",
          payload,
        });
        returnData = data;
      });
      return returnData;
    };
  },
  getAIServices({mlServiceId}) {
    return async (dispatch) => {
      const request = initRequest(dispatch);
      let returnData = {};
      await request("getAIServices", {mlServiceId}, async (data) => {
        const sortedData = sortBy(data?.data || [], (i) => i.ai_service_name);
        const options = sortedData.map((option) => ({
          value: option.id,
          label: option.ai_service_name,
          code: option.ai_service_code
        }));
        await dispatch({
          type: "SET_AI_SERVICES",
          payload:{
            mlServiceId,
            options
          },
        });
        returnData = data;
      });
      return returnData;
    };
  },
  addRemoveFavouriteModule(moduleId, isAdd) {
    return async (dispatch, getState) => {
      const request = initRequest(dispatch);
      const { formEditorReducer } = getState();
      const { moduleFavourite = [], formList } = formEditorReducer;
      // const body = normalizePayload.listKeyValuePayload(list)
      const body = { moduleId, isAdd };
      // const isEdit = body?.id
      let result = {};
      // await dispatch(setLoading('getPermissibleListItems', true))
      await request("addRemoveFavouriteModule", body, async (data = []) => {
        if (data?.success) {
          let newFavList, resultModule;
          if (isAdd) {
            const selectedFav = formList.find(
              ({ id }) => id === body?.moduleId
            );
            newFavList = [...moduleFavourite, selectedFav];
            resultModule = { [body?.moduleId]: true };
          } else {
            newFavList = moduleFavourite?.filter(
              ({ id }) => id !== body?.moduleId
            );
            resultModule = { [body?.moduleId]: false };
          }
          result = {
            success: data?.success,
            data: newFavList,
            responseMessage: data?.message,
            ...resultModule,
          };
          await dispatch({
            type: "SET_FAVOURITE_MODULE",
            payload: newFavList,
          });
        }
      });
      return result;
    };
  },
  getFavouriteModule() {
    return async (dispatch) => {
      const request = initRequest(dispatch);
      await request(
        "getFavouriteList",
        {},
        async (data = [], success = false) => {
          let newData = data?.data ? data.data : data,
            newSuccess = data?.success ? data.success : success;
          // console.log(data);
          const payload = newSuccess ? newData || [] : [];
          await dispatch({
            type: "SET_FAVOURITE_MODULE",
            payload,
          });
        }
      );
    };
  },
  getConnectorRecords() {
    return async (dispatch) => {
      console.log("this is called");
      const request = initRequest(dispatch);
      await request(
        "fetchDataConnectors",
        {},
        async (data = [], success = false) => {
          let newData = data?.data ? data.data : data,
            newSuccess = data?.success ? data.success : success;
          console.log({ newData, newSuccess });
          // console.log(data);
          // const payload = newSuccess ? newData || [] : [];
          // await dispatch({
          //   type: "SET_FAVOURITE_MODULE",
          //   payload,
          // });
        }
      );
    };
  },
  getDataConnectorDetail(id) {
    return async (dispatch) => {
      const loadingId = `data-connector-${id}`;
      try {
        await dispatch(setLoading(loadingId, true));
        const { data } = await api.fetchDataConnectorById({ recordId: id });
        const { data: details } = data || {};
        const { display_columns } = details;
        const { available } = display_columns ?? {};
        await dispatch({
          type: "SET_DATA_CONNECTOR_DETAILS",
          payload: {
            id,
            details: {
              ...details,
              hideFields: available || [],
            },
          },
        });
      } catch (e) {
      } finally {
        dispatch(setLoading(loadingId, false));
      }
    };
  },
  saveTaskScheduler(payload) {
    return async (dispatch) => {
      const request = initRequest(dispatch);
      return request("saveTaskScheduler", payload);
    };
  },
  deleteTaskSchedulerEntries(payload) {
    const { moduleId, recordIds } = payload;
    return async (dispatch, getState) => {
      const { formEditorReducer } = getState();
      const { moduleItems } = formEditorReducer;
      const request = initRequest(dispatch);
      const records = [...((moduleItems[moduleId] || {}).data || [])];
      const items = records.filter(({ id }) => !recordIds.includes(id));

      await dispatch(this.setModuleItems({ moduleId, items }));
      return request(
          "deleteTaskSchedulerEntries",
          payload,
          null,
          data => {
            const { success, message: responseMessage = 'Error deleting entry' } = data || {}
            if (!success) {
              message.error(responseMessage)
              dispatch(this.setModuleItems({ moduleId, items: records }))
            } else {
              message.success(responseMessage)
            }
          }
      );
    };
  },
  getTasksCount(){
    return async (dispatch) => {
      const request = initRequest(dispatch);
      await request("getTasksCount", {} ,async (res) => {
          const { success = false, data } = res || {};
          if (success && data) {
            await dispatch({
              type: "SET_TASKS_COUNT",
              payload: data
            });
          }
        }
      );
    };
  }
};

export default formEditorActions;
