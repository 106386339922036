const textractEndpoints = {       
    getMLProviders(data){
        return{
            url: '/api/ocrprovider/lookup',
            method: 'POST',
            data: data ?? {}
        }
    },
    getMLServices({providerId}) {
        return {
            url: '/api/ocr/machinelearninglookup',
            method: 'POST',
            data: {
                filter: {
                    logic: "AND",
                    filters: [
                        { field: "ocr_provider_id", value: providerId, operator: "eq" }
                    ]
                }                
            }
        }
    },
    getMLService(id) {
        return {
            url: `/api/ocr/getmlservice`,
            method: 'GET',
            params: {id}
        }
    },
    getAIServices({mlServiceId}) {
        return {
            url: `/api/ocr/getaiservices`,
            method: 'GET',
            params:{ mlServiceId }
        }
    },
    getAIService(id) {
        return {
            url: `/api/ocr/getaiservice`,
            method: 'GET',
            params: {id}
        }
    },
    uploadFile({ data = {} }) {
        return {
            url: '/api/ocr/upload',
            method: 'POST',
            data,
        }
    },
    getOcrResponseById(id) {
        return {
            url: `/api/ocr/getresponsebyid`,
            method: 'GET',
            params: {id}
        }
    },
    getProviderKey(providerName){
        return{
            url: `/api/ocrprovider/getbyname?ocr_provider_name=${providerName}`,
            method: 'GET'
        }
    },
    updateProviderKey({id, token}){
        return{
            url: `/api/ocrprovider/update/token?id=${id}&token=${token}`,
            method: 'PATCH'
        }
    },
}

export default textractEndpoints