
import { parseJsonString } from "@utils/json";
import * as yup from "yup";
import { SPECIAL_CHAR_REGEX } from "@constants/validation/conditions";

const bypassField = (val) => {
    try{
        const isJSONObj = parseJsonString(val)
        if(typeof isJSONObj === 'object') return true; //bypass object
        if(moment(val).isValid()) return true; //bypass date
        if((/^([0-1]?[0-9]|2[0-3]):([0-5][0-9])(:[0-5][0-9])?$/).test(val)) return true; //bypass time
        const valArr = String(val).split(',')
        if(moment(valArr[0]).isValid() && moment(valArr[1]).isValid()) return true; //bypass date between
    } catch (e) {}
    return false
}

const validateSpecialChars = (value) => {
    if (value && SPECIAL_CHAR_REGEX.test(value)) {
        // We can use ${path} here or get field by the id using the path.
        return {
            isValid:false,
            message:'Cannot contain special characters'
        }
    }
    return {isValid:true}; // Valid
}

const validateMax40Chars = (value) => {
    if (value && value.length > 40) {
        return {
            isValid:false,
            message:"Cannot exceed 40 characters"
        }
    }
    return {isValid:true}; 
}

const validateStartingSpace = (value) => {
    if (value && value.match(/^ /)) {
        return {
            isValid:false,
            message:"Cannot start with a space"
        }
    }
    return {isValid:true}; 
}

const validateStartingNumber = (value) => {
    if (value && value.match(/^\d/)) {
        return {
            isValid:false,
            message:"Cannot start with a number"
        }
    }
    return {isValid:true}; 
}

export const inputValueValidation = (value) => {
    if(value && typeof value === 'string'){
        if(bypassField(value)){
            return false;
        }
        if(value.length > 40){
            return "Value cannot exceed 40 characters"
        }
        if(value.match(/^ /)){
            return "Value cannot start with a space"
        }
        if(value.match(SPECIAL_CHAR_REGEX)){
            return "Value cannot contain special characters"
        }
    }
    return false
}

yup.addMethod(yup.string, 'integer', function () {
    return this.matches(/^\d+$/, 'The field should have digits only')
})

yup.addMethod(yup.string, 'max40Chars', function (message) {
    return this.test('max40Chars', message, function (value) {
        const { path, createError } = this;
        if(bypassField(value)) return true;
        const {isValid, message} = validateMax40Chars(value);
        return isValid ? true : createError({ path, message });
    });
});

yup.addMethod(yup.string, 'validChars', function (message) {
    return this.test('valid-chars', message, function (value) {
        const { path, createError } = this;
        if(bypassField(value)) return true;
        const {isValid, message} = validateSpecialChars(value);
        return isValid ? true : createError({ path, message });
    });
});

yup.addMethod(yup.string, 'notSpaceStarting', function (message) {
    return this.test('notSpaceStarting', message, function (value) {
        const { path, createError } = this;
        if(bypassField(value)) return true;
        const {isValid, message} = validateStartingSpace(value);
        return isValid ? true : createError({ path, message });
    });
});

yup.addMethod(yup.string, 'notNumberStarting', function (message) {
    return this.test('notNumberStarting', message, function (value) {
        const { path, createError } = this;
        if(bypassField(value)) return true;
        const {isValid, message} = validateStartingNumber(value);
        return isValid ? true : createError({ path, message });
    });
});

yup.addMethod(yup.string, 'validNaming', function (message) {
    return this.test('validNaming', message, function (value) {
        const { path, createError } = this;
        let validation = validateSpecialChars(value);
        validation = validation.isValid ? validateMax40Chars(value) : validation;
        validation = validation.isValid ? validateSpecialChars(value) : validation;
        validation = validation.isValid ? validateStartingSpace(value) : validation;
        validation = validation.isValid ? validateStartingNumber(value) : validation;
        
        const {isValid, message} = validation;
        return isValid ? true : createError({ path, message });
    });
});

export const Yup = yup;