const rpaEndpoints = {
    saveRpa(data) {
        return {
            url: '/api/rpa/save',
            method: 'POST',
            data,
        }
    },
    executeRpa(data) {
        return {
            url: '/api/rpa/execute',
            method: 'POST',
            data,
        }
    },
    testRpa(data) {
        return {
            url: '/api/rpa/execute/test',
            method: 'POST',
            data,
        }
    },
    getRpaDetail(id) {
        return {
            url: `/api/rpa/detail/${id}`,
            method: 'GET'
        }
    },
    deleteRpa(id) {
        return {
            url: `/api/rpa/delete/${id}`,
            method: 'DELETE',
        }
    },
    fetchRpaDatatables({ data }) {
        return {
            url: '/api/rpa/datatables',
            method: 'POST',
            data,
        }
    },
}

export default rpaEndpoints;