import React, { useEffect, useState } from 'react';
import { Drawer } from 'antd';
import { ButtonContainer } from './index.style';
import ResetUniGenAi from './modes/ResetUniGenAi';

// Developer Mode component that opens a Drawer via a specific key sequence
const DevMode = () => {
    const [visible, setVisible] = useState(false);
    const [keySequence, setKeySequence] = useState([]);

    const showDrawer = () => {
        setVisible(true);
    };

    const closeDrawer = () => {
        setVisible(false);
    };

    useEffect(() => {
        // Handle key down events
        const handleKeyDown = (event) => {
            // Update key sequence with the last 4 keys pressed
            const newSequence = [...keySequence, event.key].slice(-4); 
            setKeySequence(newSequence);
    
            // Check for the specific combination: ArrowRight, ArrowLeft, ArrowDown, ArrowUp
            if (newSequence.join(',') === 'ArrowRight,ArrowLeft,ArrowDown,ArrowUp') {
                showDrawer(); // Show the drawer on sequence match
                setKeySequence([]); // Reset the sequence after opening the drawer
            }
        };
    
        // Add event listener for keydown
        window.addEventListener('keydown', handleKeyDown);
        
        // Cleanup function to remove event listener on unmount
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [keySequence]); 

    return (
        <>
            <Drawer
                title="Developer Mode"
                placement="bottom"
                closable={false}
                onClose={closeDrawer}
                visible={visible}
                className="drawer--dev-mode"
            >
                <ButtonContainer>
                    <ResetUniGenAi />
                </ButtonContainer>
            </Drawer>
        </>
    );
};

export default DevMode;
