const auditTrailEndpoints = {
    getAuditTrail({ data = {}}) {
        return {
            url: '/audittrail/datatables',
            method: 'POST',
            data,
        }
    },
}

export default auditTrailEndpoints