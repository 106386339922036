import { message as antdMessage } from "antd";

const message = {}
const messageFactory = type => (msg, duration = 0) => {
    return antdMessage[type]({
        content: msg, 
        duration: duration, 
        onClick: () => {
            antdMessage.destroy()
        }
    })
}

message.error = messageFactory('error')
message.success = messageFactory('success')
message.warning = messageFactory('warning')

export default message