import React, { useEffect, useState } from "react";// , { useState }
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import { Yup } from "@utils/validation";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import Button from "react-bootstrap/Button";

import * as auth from "../_redux/authRedux";
import { login } from "../_redux/authCrud";
import { formikValidClass } from '../../../../utils/Q';
import { APP_NAME } from '../../../../data/appData';
import Password from '../../../components/Password';
import { PhoneOutlined } from "@ant-design/icons";
import { EmailOutlined } from "@material-ui/icons";
import { styled } from "@material-ui/core";
import { encrypt } from "../../../../utils/encryption";
import { deleteLocalOpenAiThread } from "../../../../utils/openai";

const HelpIcon = styled('span')(() => ({
	display: 'inline-block',
	width: '16px',
	color: '#B5B5C3',
	marginRight: '.5rem',
	'& svg': {
		width: '16px',
	}
}))

/*
	INTL (i18n) docs:
	https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage
*/
/*
	Formik+YUP:
	https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
*/

const initialValues = {
	email: "", // system
	password: "", // system
};

function Login(props) {
	const { intl, authLoading } = props;
	const [message, setMessage] = useState(null);
	const status = new URLSearchParams(window.location.search).get('status');
	const redirectTo = new URLSearchParams(window.location.search).get('ReturnUrl');

	const enableLoading = () => {
		props.setLoading(true);
	};

	const disableLoading = () => {
		props.setLoading(false);
	};

	useEffect(() => {
		if (status) {
			setMessage(status);
		}
	}, [status]);
	// const getInputClasses = (fieldname) => {
	//   if (formik.touched[fieldname] && formik.errors[fieldname]) {
	//     return "is-invalid";
	//   }
	//   if (formik.touched[fieldname] && !formik.errors[fieldname]) {
	//     return "is-valid";
	//   }
	//   return "";
	// };

	const formik = useFormik({
		initialValues,
		validationSchema: Yup.object().shape({
			email: Yup.string()
				.required(
					intl.formatMessage({
						id: "AUTH.VALIDATION.REQUIRED_FIELD",
					})
				),
			password: Yup.string()
				.min(3, "Minimum 3 characters")
				.max(50, "Maximum 50 characters")
				.required(
					intl.formatMessage({
						id: "AUTH.VALIDATION.REQUIRED_FIELD",
					})
				),
		}),
		onSubmit: (values, { setStatus, setSubmitting }) => {
			enableLoading();
			setStatus("");
			setMessage(null);
			const data = `"${encrypt(JSON.stringify({ username: values.email, password: values.password }))}"`;
			login(data)
				.then(({ data: { success, message, data } }) => {
					// setSubmitting(false);
					if (success) {
						deleteLocalOpenAiThread()
						props.login(data.requiresTwoFactor);
					} else {
						disableLoading();
						setStatus(message);
						setSubmitting(false);
					}
				})
				.catch(() => {
					// console.log("masuk sini");
					disableLoading();
					setSubmitting(false);
					setStatus(
						intl.formatMessage({
							id: "AUTH.VALIDATION.INVALID_LOGIN",
						})
					);
				});
		},
	});

	return (
		<div className="login-form login-signin" id="kt_login_signin_form">
			<div className="mb-10">
				<h3>Sign in To {APP_NAME}</h3>
				<p className="text-muted font-weight-bold">Enter your details to login to your account:</p>
			</div>
			{/*begin::Form*/}
			<form noValidate
				onSubmit={formik.handleSubmit}
				className={"form fv-plugins-bootstrap fv-plugins-framework text-left" + (formik.isSubmitting ? " cprogress" : "")}
			>
				<fieldset disabled={formik.isSubmitting}>
					{(message || formik.status) && (
						<div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
							<div className="alert-text font-weight-bold">{message ?? formik.status}</div>
						</div>
					)}

					<div className="form-group mb-5 fv-plugins-icon-container">
						<input type="text" required spellCheck={false}
							placeholder="Username"
							className={"form-control h-auto form-control-solid py-4 px-8" + formikValidClass(formik, "email")}
							name="email"
							autoComplete="off"
							{...formik.getFieldProps("email")}
						/>
						{formik.touched.email && formik.errors.email ? (
							<div className="fv-plugins-message-container">
								<div className="fv-help-block">{formik.errors.email}</div>
							</div>
						) : null}
					</div>

					<div className="form-group mb-2 fv-plugins-icon-container">
						{/* <input
              placeholder="Password"
              type="password" 
              className={"form-control h-auto form-control-solid py-4 px-8" + formikValidClass(formik, "password")} 
              name="password"
              {...formik.getFieldProps("password")}
            /> */}

						<Password required
							btnProps={{
								className: "py-4"
							}}
							placeholder="Password"
							id="password"
							className={"h-auto form-control-solid py-4 px-8" + formikValidClass(formik, "password")}
							value={formik.values.password}
							onChange={formik.handleChange}
						/>

						{formik.touched.password && formik.errors.password && (
							<div className="fv-plugins-message-container">
								<div className="fv-help-block">{formik.errors.password}</div>
							</div>
						)}
					</div>

					{/*Commit Id a848055df4a0b7c72256c2bb2c213d81f123fd34*/}
					<div className="form-group fv-plugins-icon-container">
						<Link
							to="/authentication/forgot-password"
							id="kt_login_forgot"
							className="float-right mb-3"
						>
							<FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
						</Link>
					</div>

					<div className="form-group mb-5 fv-plugins-icon-container">{/*  opacity-60 */}
						<Button
							id="kt_login_signin_submit"
							type="submit"
							disabled={formik.isSubmitting}
							className="btn btn-primary font-weight-bold px-9"
							block
						>
							<span>Sign In</span>
							{authLoading && <span className="ml-3 spinner spinner-white" />}
						</Button>
					</div>

					<h6 className="hr-h my-2">Or Sign in with</h6>
					<div className="row">
						<div className="col-6">
							<a
								href={redirectTo ? `/auth/login?returnUrl=${redirectTo}` : "/auth/login"}
								className="btn btn-default text-dark btn-block font-weight-bold my-3"
								id="kt_login_microsoft"
							>
								<img alt="" src="/icons/ms-button.png" style={{ width: '20px' }} className="mr-2" /> Microsoft
							</a>
						</div>
						<div className="col-6">
							<a
								href={redirectTo ? `/auth/saml?returnUrl=${redirectTo}` : "/auth/saml"}
								className="btn btn-default text-dark btn-block font-weight-bold my-3"
								id="kt_login_microsoft"
							>
								<i alt="" style={{ width: '20px' }} className="fa fa-user-lock text-primary mr-2" /> SAML
							</a>
						</div>
					</div>
					<p className="mt-4">If you need help logging in, please have your username ready and contact FinanSys on:</p>
					<div className="vcard">
						<div>
							<HelpIcon>
								<EmailOutlined />
							</HelpIcon>
							<a href="mailto:support@finansys.com" className="email">support@finansys.com</a>
						</div>
						<div className="mt-4">
							<HelpIcon>
								<PhoneOutlined />
							</HelpIcon>
							<a href="tel:+442074569839" className="tel">+44 20 7456 9839</a>
						</div>
					</div>
				</fieldset>
			</form>
			{/*end::Form*/}
		</div>
	);
}

const mapStateToProps = state => {
	return {
		authLoading: state.auth.authLoading,
	}
}

export default injectIntl(connect(mapStateToProps, auth.actions)(Login));
