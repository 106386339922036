import { isStr, jsonParse } from "@utils/Q";
import { parseJsonString } from "@utils/json";

// Scroll tab content & focus new item
export function scrollFocusEl(et, lastEl, preventScroll = true) {
  const tabFieldSet = et?.closest(".tab-content-fieldset");

  if (tabFieldSet) {
    tabFieldSet.scrollBy({
      top: tabFieldSet.scrollHeight,
      // left: 0,
      behavior: "smooth",
    });

    // const lastEl = et.closest('.dd-add-validrule').previousElementSibling;
    // console.log('scrollFocusEl lastEl: ', lastEl);
    if (lastEl) lastEl.focus({ preventScroll }); // OPTION preventScroll: true
  }
}

// Set focus other input to validate
export function onChangeToFocus(cls = ".fs-filterValue") {
  let filterValue = document.querySelector(cls);
  if (filterValue) {
    filterValue.tagName === "DIV"
      ? filterValue.firstChild.firstChild.focus()
      : filterValue.focus();
  }
}

// AWS File
export const awsFile = (key, token) =>
  "/api/form/AWSS3/GetFile?key=" +
  encodeURIComponent(key) +
  "&x-token=" +
  token;

// Parsing fieldType file, image
export function parseFieldUploadData(isFile, value, token) {
  let parseValue;

  // when value is null, or
  // parsed value from stringified format ('[]') is of an empty array, or
  // is an empty array
  if (
    !value ||
    parseJsonString(value)?.length === 0 ||
    (Array.isArray(value) && value.length === 0)
  ) {
    return null;
  }

  const genValue = (value, type) => {
    let data;
    try {
      if (type === "url") {
        data = value
          ? Array.isArray(value)
            ? awsFile(value?.[0], token)
            : value && jsonParse(value, value || [])
            ? awsFile(jsonParse(value, value || [])?.[0], token)
            : awsFile(value, token)
          : value;
      } else {
        data = Array.isArray(value)
          ? (value[0] || "")?.split("/").pop()
          : value && jsonParse(value, value || [])
          ? jsonParse(value, value || [])[0]
              ?.split("/")
              .pop()
          : isStr(value)
          ? value.split("/").pop()
          : String(value);
      }
    } catch (e) {}
    return data;
  };
  if (isStr(value) || Array.isArray(value)) {
    // (isStr(value) ? jsonParse(value, []) : value)
    parseValue = isFile
      ? jsonParse(value, value || []).map((v) => {
          if (isStr(v)) {
            // From backend
            return {
              uid: v,
              url: awsFile(v, token),
              name: v.split("/").pop(),
              status: "done",
              fileKey: v,
            };
          }
          return {
            ...v,
            url: awsFile(v.fileKey, token),
          };
        })
      : value && {
          uid: "imageSingleUpload",
          url: genValue(value, "url"),
          name: genValue(value, "name"),
          status: "done",
          fileKey: "imageSingleUpload",
        };
  } else if (value?.fileKey) {
    parseValue = {
      ...value,
      url: awsFile(value.fileKey, token),
    };
  }

  return parseValue;
}

export const mapNewData = (predicate, oldData, newData) => {
  if (predicate) {
    return typeof newData === "function" ? newData() : newData;
  }
  return oldData;
};

export const iterateNewFields = ({
  fieldData,
  normalizedFieldData,
  section,
  sectionId,
  sectionRowId,
}) => (element, index) => {
  return mapNewData(index === sectionId, element, () => {
    const rows = section.rows[sectionRowId];
    let fields = [normalizedFieldData];
    if (Array.isArray(rows?.fields)) {
      fields = rows.fields.map((item) => {
        return mapNewData(
          item.fid === fieldData.fid,
          item,
          normalizedFieldData
        );
      });
    }

    let newSectionData = {
      ...element,
      rows: element.rows.map((row, rowIdx) => {
        return mapNewData(rowIdx === sectionRowId, row, { ...row, fields });
      }),
    };

    if (element.rows.length < 1 && element.fields < 1) {
      newSectionData = {
        ...element,
        fields: [normalizedFieldData],
        rows: [
          {
            rowIndex: "0",
            fields: [normalizedFieldData],
          },
        ],
      };
    }

    return newSectionData;
  });
};

export const isStaticField = ({ isDisabled, fieldType }) => {
  const isCalculated = [
    "calculation-remote-lookup",
    "calculation-lookup",
    "calculation-reference-dynamic",
    "image",
    "file",
    "multi-file",
    'input-external',
  ].includes(fieldType);
  return isDisabled && !isCalculated;
};

export const newRowKeys = (columns, timezone) => {
  let keys = {};  
  const { generateDateTime, toLocalMoment, datetime_format, date_format, time_format } = timezone || {};
  (columns || []).forEach((f) => {
    if (f.fieldType === "calculation-number") {
      keys[f.id] = f.value.length > 0 ? f.value : 0;
    } else if (f.fieldType === "number") {
      keys[f.id] = f.value;
    } else if (f.fieldType === "date") {
      keys[f.id] = f.value ? generateDateTime({ value: f.value, isActual: f.today })?.format(date_format) : null;
    } else if (f.fieldType === "datetime") {
      keys[f.id] = f.value ? toLocalMoment(generateDateTime({ value: f.value, isActual: f.today }))?.format(datetime_format) : null;
    } else if (f.fieldType === "time") {
      keys[f.id] = f.value ? toLocalMoment(generateDateTime({ value: f.value, isActual: f.now }))?.format(time_format) : null;
    } else {
      keys[f.id] = f.value ?? "";
    }
  });
  return keys
}

export const filterFields = (sections, key) => {
  return sections?.reduce((result, section) => {
    const { rows } = section;
    return [
        ...result,
        ...rows.reduce((res, row) => {
            return [
                ...res,
                ...row.fields.filter((f) => f.fieldType?.includes(key)).map((f) => {
                    return f;
                })
            ]
        }, [])
    ]
  }, []);
}