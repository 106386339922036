/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useMemo } from "react";
import { OverlayTrigger, Tooltip, Dropdown } from "react-bootstrap";
//import { shallowEqual, useSelector } from "react-redux";
// import { NavLink } from "react-router-dom";
//import SVG from "react-inlinesvg";
import objectPath from "object-path";

import { useHtmlClassService } from "../../../_core/MetronicLayout";
//import { toAbsoluteUrl } from "../../../../_helpers";
import { DropdownTopbarItemToggler } from "../../../../_partials/dropdowns";
import { HELP_URL } from "../../../../../app/constants/links";

export function HelpDropdown() {
  const uiService = useHtmlClassService();
  const layoutProps = useMemo(() => {
    return {
      offcanvas: objectPath.get(uiService.config, "extras.quick-actions.layout") === "offcanvas",
    };
  }, [uiService]);

  //const { menus } = useSelector(({ applicationMenu }) => ({
  //  menus: applicationMenu.menus,
  //}), shallowEqual);
  //console.log('QuickActionsDropdown menus: ', menus);

  return (
    <>
      {layoutProps.offcanvas && (
        <OverlayTrigger
          placement="left"
          overlay={<Tooltip id="fs-help-tooltip">Help</Tooltip>}
        >
          <div className="topbar-item">
            <div
              className="btn btn-lg btn-icon btn-clean btn-dropdown mr-1" //  btn-lg
              id="fs_help_toggle"
            >
              <i className="far fa-question-circle text-primary" />
            </div>
          </div>
        </OverlayTrigger>
      )}

      {!layoutProps.offcanvas && (
        <Dropdown drop="down" alignRight>
          <Dropdown.Toggle
            as={DropdownTopbarItemToggler}
            id="fs_help_panel_toggle"
          >
            <OverlayTrigger
              placement="bottom"
              overlay={<Tooltip id="fs-help-tooltip">Help</Tooltip>}
            >
              <div className="btn btn-lg btn-icon btn-clean btn-dropdown mr-1">
                <i className="far fa-question-circle text-primary" />
              </div>
            </OverlayTrigger>
          </Dropdown.Toggle>

          <Dropdown.Menu className="p-0 m-0 dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-lg dd-menu-head-bg">
            <form>
              <div className="d-flex flex-column align-items-center justify-content-center py-7 bg-gradient-main rounded-top">
                <h3 className="text-white font-weight-bold mb-0">
                  Help
                </h3>
              </div>

              <div className="row no-gutters">
                {
                  [
                    // { href: "/settings/application-menu", title: "Application Menu", icon: "/media/svg/icons/Design/Layers.svg" },,
                    { href: HELP_URL, title: "Online Documentation", icon: "fal fa-atlas", target: "_blank", rel: "noopener noreferrer" },
                    { href: "mailto:support@finansys.com", title: "support@finansys.com", icon: "fal fa-envelope" }, // eslint-disable-next-line
                    { href: "https://www.teamviewer.com/en-us/download/windows/", title: "TeamViewer Client", icon: "fal fa-user-md-chat", target: "_blank", rel: "noopener noreferrer" },
                    { href: "tel:+44-20-7456-9839", title: "+44-20-7456-9839", icon: "fal fa-phone" }, // label: "+44-20-7456-9839", 
                  ].map((v, i) => 
                    <a key={i} 
                      href={v.href} 
                      rel={v.rel} 
                      target={v.target} 
                      className="col-6 dropdown-item d-flex flex-column justify-content-center align-items-center h-100 py-10 px-0 text-center border-right border-bottom"
                    >
                      <i className={v.icon + " fa-2x text-muted"} />
                      <b className="d-block mt-2 ws-normal">{v.title}</b>{/* text-break */}
                      {/* {v.label} */}
                    </a>
                  )}
              </div>
            </form>
          </Dropdown.Menu>
        </Dropdown>
      )}
    </>
  );
}


