// import { Qid } from '../../utils/Q';

const INIT_COL = 4; // // INITIAL FIELD COLUMN SIZE
// const UNTITLED = "Untitled field";

const INIT_FIELD_TYPES = [
    {
        label: "Text",
        icon: "text-size",
        fieldType: "text",
        required: false,
        id: null,
        helpText: null,
        value: "",
        isComputedField: false, // LABEL: Is this a computed field?
        formula: null,
        regex: null,
    }, {
        label: "Text Area",
        icon: "align-left",
        fieldType: "textarea",
        required: false,
        id: null,
        helpText: null,
        regex: null,
        // value: ""
    }, {
        label: "Number",
        icon: "number",
        fieldType: "number",
        required: false,
        id: null,
        helpText: null,
        value: "",
        isComputedField: false,
        formula: null,
        decimalPlaces: "",
        format: 'None',
        regex: null,
    }, {
        label: "Date",
        icon: "calendar",
        fieldType: "date",
        required: false,
        id: null,
        helpText: null,
        value: "",
        isComputedField: false,
        formula: null,
        today: false,
        regex: null,
    }, {
        label: "Date & Time",
        icon: "calendar-alt",
        fieldType: "datetime",
        required: false,
        id: null,
        helpText: null,
        value: "",
        isComputedField: false,
        formula: null,
        regex: null,
    }, {
        label: "Time",
        icon: "time",
        fieldType: "time",
        required: false,
        id: null,
        helpText: null,
        value: "",
        isComputedField: false,
        formula: null
    }, {
        label: "Basic", // BEFORE = Dropdown
        icon: "dropdown-basic",
        fieldType: "dropdown",
        required: false,
        id: null,
        helpText: null,
        value: null, // Dropdown / Select items | OPTION value: undefined / []
        listToUse: null, // BEFORE : // selectList: "", // LABEL: Which list to use? | ""
        // LABEL: Values to choose from
        // ...
        regex: null,
    }, {
        label: "Reference", // BEFORE = Lookup
        icon: "dropdown-reference",
        fieldType: "lookup",
        required: false,
        id: null,
        helpText: null,
        flowToLookUp: null, // LABEL: Choose a flow to look up | @TYPE: Select / Dropdown
        selectFieldsByThisProcess: [], // LABEL: Select all fields that will be used by this process | @TYPE: Multi select dropdown
        hideFieldsDuringSelection: false, // LABEL: Hide fields from displaying during selection | @TYPE: Checkbox
        hideFields: [],
        sortValuesBy: null,// LABEL: Sort values by | @TYPE: Select / Dropdown
        sortType: "Ascending", // LABEL: Sort type | @TYPE: Select / Dropdown
        filters: null, // LABEL: Restrict the values shown based on these filters | @TYPE: Button ...MORE
        autoSelectFirstValueByFilters: false, // LABEL: Auto-select value based on the filters | @TYPE: Checkbox
        isTable: false,
        isTableLookup: false,
        moduleId: null,
        tableName: '',
    }, {
        label: "External", // BEFORE = Remote Lookup
        icon: "dropdown-external",
        fieldType: "remote-lookup",
        required: false,
        id: null,
        helpText: null,
        selectFieldsByThisProcess: [], // LABEL: Select all fields that will be used by this process | @TYPE: Multi select dropdown
        hideFieldsDuringSelection: false, // LABEL: Hide fields from displaying during selection | @TYPE: Checkbox
        hideFields: [],
        url: "",
        requestType: "GET",
        headers: [],
        body: [],
        params: [],
        responseFormat: "JSON",
        responsePath: "",
        dataType: "text",
        resultType: "select",
        addGovHeaders: false,
        autoSelectFirstValueByFilters: false,
        rawRequestConfig: {},
    }, {
        label: "Image",
        icon: "image",
        fieldType: "image",
        required: false,
        id: null,
        helpText: null
    }, {
        label: "Document",
        icon: "paperclip",
        fieldType: "file", // attachment | file
        required: false,
        id: null,
        helpText: null
    }, {
        label: "Multiple Upload",
        icon: "paperclip",
        fieldType: "multi-file",
        required: false,
        id: null,
        helpText: null
    }, {
        label: "Signature",
        icon: "signature",
        fieldType: "signature",
        required: false,
        id: null,
        helpText: null
    }, {
        label: "Email",
        icon: "envelope",
        fieldType: "email",
        required: false,
        id: null,
        helpText: null,
        value: "",
        regex: null,
    }, {
        label: "Slider",
        icon: "sliders-h",
        fieldType: "slider",
        id: null,
        helpText: null,
        value: 0, // Slider step length
        minValue: null, // LABEL: Minimum value | Number minimum value
        maxValue: "", // LABEL: Maximum value | Number maximum value
        intervalSize: "" // LABEL: Interval size | Number Interval Size value
    }, {
        label: "Toggle",
        icon: "toggle-on",
        fieldType: "switch",
        id: null,
        helpText: null,
        value: false, // With Switch
    }, {
        label: "Rating",
        icon: "star",
        fieldType: "rating",
        required: false,
        id: null,
        helpText: null,
        value: 0, // Rating star length
    }, {
        label: "User",
        icon: "user-circle",
        fieldType: "user",
        required: false,
        id: null,
        helpText: null,
        regex: null,
        // Checkbox:
        // LABEL: Allow multiple users to be selected:
        multiUserSelected: false, // NOTE: IF CHECKED autoSelectFirstValueByFilter disabled
        autoSelectFirstValueByFilter: false, // LABEL: Auto-select value based on the filter(s):
        // LABEL: Filters ???
    }, {
        label: "Multi-select: Basic",
        icon: "multiselect-basic",
        fieldType: "checkbox",
        required: false,
        id: null,
        helpText: null,
        // value: null, // Dropdown / Select items

        // LABEL: Show field as: For display Checkboxs as Dropdown / Checkbox
        // showFieldAs: "multi-select", // multi-select-dropdown | Radio
        listToUse: null, // LABEL: Which list to use? | ""
        // LABEL: Values to choose from:
        // valuesToChooseFrom: "",
    }, {
        label: "Checklist",
        icon: "tasks",
        fieldType: "checklist",
        id: null,
        helpText: null,
        canAddChecklistValues: false, // LABEL: User can add their own checklist values | @TYPE: Checkbox
        canDeleteChecklistValues: false, // LABEL: User can delete checklist values | @TYPE: Checkbox
        canAddRenameChecklist: false, 
        listToUse: null, // LABEL: Which list to use? (Dropdown / Select) | @TYPE: Array | null
        // LABEL: Values to choose from
        // ...
    }, {
        label: "Button",
        icon: "mouse",
        fieldType: "button",
        required: false,
        id: null,
        helpText: null,
        url: "",
        requestType: "GET",
        headers: [],
        body: [],
        params: [],
        responseFormat: "JSON",
        responsePath: "",
        dataType: "text",
        resultType: "",
        fieldToResult: null,
        renderType: "none",
        renderTarget: null,
        showInModal: false,
        submitAllFields: false,
        addGovHeaders: false,
        showAsLink: false,
    }, {
        label: "Pull Data",
        icon: "pulldata",
        fieldType: "pull-data",
        required: false,
        id: null,
        helpText: null,
        flowToLookUp: null,
        selectFieldsByThisProcess: [],
        hideFieldsDuringSelection: false,
        hideFields: [],
        filters: null,
        autoSelectFirstValueByFilters: false,
        isTable: false,
        isTableLookup: false,
        moduleId: null,
        renderTarget: null,
        tableName: '',
    }, {
        label: "Text Area (Rich)",
        icon: "edit",
        fieldType: "richtext",
    }, {
        label: "Text Area (HTML)",
        icon: "richtext-html",
        fieldType: "richtext-html",
    }, {
        label: "Auto Number",
        icon: "auto-number", // sequence-number fsi
        fieldType: "sequence-number",
        id: null,
        helpText: null,
        format: "01",
        formula: "", // OPTION key = prefix | OPTION value = null
        stepSequence: "Start", // Extends from Process
        // ...MORE
    }, {
        label: "Multi-select: User",
        icon: "multiselect-user",
        fieldType: "multi-select-user",
        required: false,
        id: null,
        helpText: null,
        regex: null,
        // Checkbox:
        // LABEL: Allow multiple users to be selected:
        multiUserSelected: true, // NOTE: IF CHECKED autoSelectFirstValueByFilter disabled
        autoSelectFirstValueByFilter: false, // LABEL: Auto-select value based on the filter(s):
        // LABEL: Filters ???
    },
    {
        label: "Calculated Text",
        icon: "calculation-text",
        fieldType: "calculation-text",
        required: false,
        id: null,
        helpText: null,
        value: "",
        isComputedField: true,
        isSendEmail: false,
        formula: null
    }, {
        label: "Calculated Number",
        icon: "calculation-number",
        fieldType: "calculation-number",
        required: false,
        id: null,
        helpText: null,
        value: "",
        isComputedField: true,
        formula: null,
        decimalPlaces: "",
    }, {
        label: "Calculated Currency",
        icon: "calculation-currency",
        fieldType: "calculation-currency",
        required: false,
        id: null,
        helpText: null,
        value: "",
        isComputedField: true,
        formula: null,
        decimalPlaces: "",
    }, {
        label: "Calculated Date",
        icon: "calculation-date",
        fieldType: "calculation-date",
        required: false,
        id: null,
        helpText: null,
        value: "",
        isComputedField: true,
        formula: null
    }, {
        label: "Calculated Date & Time",
        icon: "calculation-date-time",
        fieldType: "calculation-date-time",
        required: false,
        id: null,
        helpText: null,
        value: "",
        isComputedField: true,
        formula: null
    }, {
        label: "Calculated Email",
        icon: "calculation-email",
        fieldType: "calculation-email",
        required: false,
        id: null,
        helpText: null,
        value: "",
        isComputedField: true,
        formula: null
    }, {
        label: "Calculated Time",
        icon: "calculation-time",
        fieldType: "calculation-time",
        required: false,
        id: null,
        helpText: null,
        value: "",
        isComputedField: true,
        formula: null
    }, {
        label: "Calculated Toggle",
        icon: "toggle-on",
        fieldType: "calculation-switch",
        id: null,
        helpText: null,
        value: true,
        isComputedField: true,
        formula: null
    }, {
        label: "Password",
        icon: "password",
        fieldType: "password",
        required: false,
        id: null,
        helpText: null,
        value: "",
        isComputedField: false,
        formula: null,
        regex: null,
    }, {
        label: "Barcode",
        icon: "barcode",
        fieldType: "barcode",
        required: false,
        id: null,
        helpText: null,
        value: "",
        barcodeValue: "",
        isComputedField: true,
        formula: null
    }, {
        label: "QR/Barcode Scanner",
        icon: "barcode",
        fieldType: "barcode-scanner",
        required: false,
        id: null,
        helpText: null,
        value: "",
        barcodeValue: "",
        isComputedField: false,
        formula: null
    },
    {
        label: "Multi-select: Reference", // BEFORE = Lookup
        icon: "multiselect-reference",
        fieldType: "multi-select-reference",
        required: false,
        id: null,
        helpText: null,
        flowToLookUp: null, // LABEL: Choose a flow to look up | @TYPE: Select / Dropdown
        selectFieldsByThisProcess: [], // LABEL: Select all fields that will be used by this process | @TYPE: Multi select dropdown
        hideFieldsDuringSelection: false, // LABEL: Hide fields from displaying during selection | @TYPE: Checkbox
        hideFields: [],
        fieldOptions: [],
        sortValuesBy: null,// LABEL: Sort values by | @TYPE: Select / Dropdown
        sortType: "Ascending", // LABEL: Sort type | @TYPE: Select / Dropdown
        filters: null, // LABEL: Restrict the values shown based on these filters | @TYPE: Button ...MORE
        autoSelectFirstValueByFilters: false, // LABEL: Auto-select value based on the filters | @TYPE: Checkbox
        isTable: false,
        isTableLookup: false,
        moduleId: null,
        tableName: '',
    },
    {
        label: "Multi-select: External", // BEFORE = Remote Lookup
        icon: "multiselect-external",
        fieldType: "multi-select-external",
        required: false,
        id: null,
        helpText: null,
        selectFieldsByThisProcess: [], // LABEL: Select all fields that will be used by this process | @TYPE: Multi select dropdown
        hideFieldsDuringSelection: false, // LABEL: Hide fields from displaying during selection | @TYPE: Checkbox
        hideFields: [],
        url: "",
        requestType: "GET",
        headers: [],
        body: [],
        params: [],
        responseFormat: "JSON",
        responsePath: "",
        dataType: "text",
        resultType: "select",
        addGovHeaders: false,
        autoSelectFirstValueByFilters: false,
    },
    {
        label: "Get External Data", // BEFORE = Remote Lookup
        icon: "get-external-data",
        fieldType: "input-external",
        required: false,
        id: null,
        helpText: null,
        selectFieldsByThisProcess: [], // LABEL: Select all fields that will be used by this process | @TYPE: Multi select dropdown
        hideFieldsDuringSelection: false, // LABEL: Hide fields from displaying during selection | @TYPE: Checkbox
        hideFields: [],
        url: "",
        requestType: "GET",
        headers: [],
        body: [],
        params: [],
        responseFormat: "JSON",
        responsePath: "",
        dataType: "text",
        resultType: "select",
        addGovHeaders: false,
        autoSelectFirstValueByFilters: false,
    },{
        label: "Calculated User",
        icon: "user-circle",
        fieldType: "calculation-user",
        required: false,
        id: null,
        helpText: null,
        regex: null,
        multiUserSelected: false, // NOTE: IF CHECKED autoSelectFirstValueByFilter disabled
        autoSelectFirstValueByFilter: true, // LABEL: Auto-select value based on the filter(s):
    },{
        label: "Calculated External", // BEFORE = Remote Lookup
        icon: "dropdown-external",
        fieldType: "calculation-remote-lookup",
        required: false,
        id: null,
        helpText: null,
        selectFieldsByThisProcess: [], // LABEL: Select all fields that will be used by this process | @TYPE: Multi select dropdown
        hideFieldsDuringSelection: false, // LABEL: Hide fields from displaying during selection | @TYPE: Checkbox
        hideFields: [],
        url: "",
        requestType: "GET",
        headers: [],
        body: [],
        params: [],
        responseFormat: "JSON",
        responsePath: "",
        dataType: "text",
        resultType: "select",
        addGovHeaders: false,
        autoSelectFirstValueByFilters: true,
        rawRequestConfig: {},
    },{
        label: "Calculated Reference", // BEFORE = Lookup
        icon: "dropdown-reference",
        fieldType: "calculation-lookup",
        required: false,
        id: null,
        helpText: null,
        flowToLookUp: null, // LABEL: Choose a flow to look up | @TYPE: Select / Dropdown
        selectFieldsByThisProcess: [], // LABEL: Select all fields that will be used by this process | @TYPE: Multi select dropdown
        hideFieldsDuringSelection: false, // LABEL: Hide fields from displaying during selection | @TYPE: Checkbox
        hideFields: [],
        sortValuesBy: null,// LABEL: Sort values by | @TYPE: Select / Dropdown
        sortType: "Ascending", // LABEL: Sort type | @TYPE: Select / Dropdown
        filters: null, // LABEL: Restrict the values shown based on these filters | @TYPE: Button ...MORE
        autoSelectFirstValueByFilters: true, // LABEL: Auto-select value based on the filters | @TYPE: Checkbox
        isTable: false,
        isTableLookup: false,
        moduleId: null,
        tableName: '',
    },{
        label: "Calculated Reference - Dynamic",
        icon: "dropdown-reference",
        fieldType: "calculation-reference-dynamic",
        required: false,
        id: null,
        helpText: null,
        flowToLookUp: null,
        filters: null,
        autoSelectFirstValueByFilters: true,
        isTable: false,
        isTableLookup: false,
        moduleId: null,
        valueKey: null,
        tableName: '',
    },{
        label: "Calculated Multiple Reference",
        icon: "multiselect-reference",
        fieldType: "calculation-multiple-lookup",
        required: false,
        id: null,
        helpText: null,
        flowToLookUp: null,
        filters: null,
        autoSelectFirstValueByFilters: true,
        isTable: false,
        isTableLookup: false,
        moduleId: null,
        valueKey: null,
        tableName: '',
    },{
        label: "Calculated Element",
        icon: "calculation-element",
        fieldType: "calculation-element",
        required: false,
        id: null,
        helpText: null,
        value: "",
        isComputedField: true,
        formula: null
    }
].map(item => ({
    ...item,
    columnWidth: INIT_COL,
    fieldName: "Untitled field",
    editable: true, // For edit id only once when add new field
}));

const F_DATE = "yyyy-MM-DD";// MM/DD/YYYY
const F_DATETIME = "yyyy-MM-DD HH:mm:ss";
const F_DATE_DISPLAY = "yyyy-MM-DD";// MM/DD/YYYY
const F_DATETIME_DISPLAY = "yyyy-MM-DD HH:mm:ss";
const F_TIME = "HH:mm:ss";
const F_OPTIONS = [
    {value:'YYYY-MM-DD', text:'YYYY-MM-DD (International)'},
    {value:'DD-MM-YYYY', text:'DD-MM-YYYY (European)'},
    {value:'MM-DD-YYYY', text:'MM-DD-YYYY (US)'}
]
const F_LAUNCH_OPTIONS = [
    { label: "Home", value: "home" },
    { label: "Dashboard & Reports", value: "dashboard&reports" },
    { label: "Quick Report", value: "quickreport" },
    { label: "Apps", value: "apps" },
  ]
export {
    INIT_COL,
    INIT_FIELD_TYPES,
    F_DATE,
    F_DATETIME,
    F_DATE_DISPLAY,
    F_DATETIME_DISPLAY,
    F_TIME,
    F_OPTIONS,
    F_LAUNCH_OPTIONS
};
