import { Button } from "antd"
import { useCallback } from "react"
import useFormActions from "@utils/hooks/useFormActions"
import message from "@utils/message"

const ResetUniGenAi = () => {
    const {
        loading,
        getFormDetails,
        saveForm,
    } = useFormActions()

    const onReset = useCallback(async () => {
        // Fetch form details to be passed to the saveForm method
        const { sections, flowData } = await getFormDetails()
        
        // Save form data by resetting the threadId
        const response = await saveForm({ 
            sections, 
            flowData, 
            threadId: null,
        }, true)
    
        // Handle response
        // Show success message
        const { success, message: responseMessage } = response || {}
        if (success) {
            message.success(responseMessage)
        }
    
        // Reload the window
        window.location?.reload()
    }, [])    

    const isLoading = loading?.saveForm || loading?.getFormDetail || loading?.getModuleConfig

    return (
        <Button 
            onClick={onReset}
            loading={isLoading}
        >
            Reset Uni - GenAi
        </Button>
    )
}

export default ResetUniGenAi