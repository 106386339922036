const initialState = {
    fields: [],
    lastHistory: {
        tab: 'Tasks',
        moduleId: '',
        page: 1,
        size: null
    },
    permissibleList: [],
    permissibleListCategoryOptions: [],
    permissibleListItems: {},
    permissibleListCategories: {},
    formPreview: {},
    formDetails: {},
    formFields: {},
    tableFields: {},
    formList: [],
    loading: {},
    moduleItems: {},
    moduleTasks: {},
    moduleLookup: [],
    columnLookup: [],
    moduleCategories: [],
    dataConnectorDetails: {},
    mlProviders: [],
    mlServices: [],
    aiServices: []
}

const formEditorReducer = (state = initialState, action) => {
    const { formId, formState, formDetails, formFields, tableFields, moduleItems, moduleTasks } = action.payload || {}
    switch (action.type) {
        case 'SET_LAST_HISTORY':
            return {
                ...state,
                lastHistory: action.payload
            }
        case 'SET_FIELDS':
            const rawFields = action.payload
            let uniqueRawFields = [];
            for(let field of rawFields){
                if(!uniqueRawFields.find((item)=> item.fid === field.fid)){
                    uniqueRawFields.push(field);
                }
            }
            return {
                ...state,
                fields: uniqueRawFields
            }
        case 'SET_PERMISSIBLE_LIST':
            return {
                ...state,
                permissibleList: action.payload
            }
        case 'ADD_PERMISSIBLE_LIST':
            return {
                ...state,
                permissibleList: [
                    ...state.permissibleList,
                    action.payload
                ]
            }
        case 'SET_PERMISSIBLE_LIST_CATEGORY_OPTIONS':
            return {
                ...state,
                permissibleListCategoryOptions: action.payload
            }
        case 'ADD_PERMISSIBLE_LIST_CATEGORY_OPTIONS':
            return {
                ...state,
                permissibleListCategoryOptions: [
                    ...state.permissibleListCategoryOptions,
                    action.payload
                ]
            }
        case 'SET_PERMISSIBLE_LIST_ITEMS':
            return {
                ...state,
                permissibleListItems: {
                    ...state.permissibleListItems,
                    [action.payload.listId]: action.payload.items
                }
            }
        case 'SET_PERMISSIBLE_LIST_CATEGORIES':
            return {
                ...state,
                permissibleListCategories: {
                    ...state.permissibleListCategories,
                    [action.payload.listId]: action.payload.categories
                }
            }
        case 'SET_FORM_DETAILS':
            return {
                ...state,
                formDetails: {
                    ...state.formDetails,
                    [formId]: {
                        ...(state.formDetails[formId] || {}),
                        ...formDetails,
                    }
                }
            }
        case 'SET_FORM_FIELDS':
            return {
                ...state,
                formFields: {
                    ...state.formFields,
                    [formId]: formFields,
                }
            }
        case 'SET_TABLE_FIELDS':
            return {
                ...state,
                tableFields: {
                    ...state.tableFields,
                    [formId]: tableFields,
                }
            }
        case 'SET_PREVIEW_FORM':
            return {
                ...state,
                formPreview: {
                    ...state.formPreview,
                    [formId]: {
                        ...(state.formPreview[formId] || {}),
                        ...formState
                    }
                }
            }
        case 'SET_MODULE_ITEMS':
            return {
                ...state,
                moduleItems: {
                    ...state.moduleItems,
                    [formId]: moduleItems,
                }
            }
        case 'SET_MODULE_TASKS':
            return {
                ...state,
                moduleTasks: {
                    ...state.moduleTasks,
                    [formId]: moduleTasks,
                }
            }
        case 'SET_FORM_LIST':
            return {
                ...state,
                formList: action.payload,
            }
        case 'DELETE_FORM_LIST_ITEM':
            const forms = [...state.formList]
            const deleteIndex = forms.findIndex(form => form.id === formId)
            forms.splice(deleteIndex, 1)
            return {
                ...state,
                formList: forms,
            }
        case 'SET_MODULE_LOOKUP':
            return {
                ...state,
                moduleLookup: action.payload,
            }
        case 'SET_COLUMN_LOOKUP':
            return {
                ...state,
                columnLookup: action.payload,
            }
        case 'SET_MODULE_CATEGORIES':
            return {
                ...state,
                moduleCategories: action.payload,
            }
        case 'SET_ML_PROVIDERS':
            return {
                ...state,
                mlProviders: action.payload,
            }
        case 'SET_ML_SERVICES':
            return {
                ...state,
                mlServices: action.payload,
            }
        case 'SET_AI_SERVICES':
            return {
                ...state,
                aiServices: action.payload,
            }
        case 'SET_LOADING':
            return {
                ...state,
                loading: {
                    ...state.loading,
                    [action.payload.key]: action.payload.status,
                }
            }
        case 'SET_FAVOURITE_MODULE':
            return {
                ...state,
                moduleFavourite: action.payload
            }
        case 'SET_APPLICATIONS_MODULE':
            return {
                ...state,
                moduleList: action.payload
            }
        case 'SET_TASKS_COUNT':
            return {
                ...state,
                tasksCount: action.payload
            }
        case 'SET_QUICK_APP_MODULE':
            return {
                ...state,
                quickApp: action.payload
            }
        case 'SET_DATA_CONNECTOR_DETAILS':
            return {
                ...state,
                dataConnectorDetails: {
                    ...state.dataConnectorDetails,
                    [action.payload.id]: action.payload.details,
                }
            }
        default:
            return { ...state }
    }
}

export default formEditorReducer